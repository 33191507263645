.w3status {
  padding: 0.75rem 0;
  &__item {
    margin: 0 0 8px 0;
    &:last-of-type {
      margin: 0;
    }
  }
}

.w3errors {
  &__type {
    padding: 1px 6px;
    border-radius: 6px;
    border: 1px solid #ccc;
    font: caption;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    &--average {
      background-color: #ffc;
    }
    &--bad {
      background-color: #fcc;
    }
    &--fatal {
      background-color: #c76363;
    }
  }
  &__details {
    &__message {
      font-size: 16px;
      font-weight: 600;
    }
    &__position {
      margin: 8px 0 0 0;
      font-weight: 400;
    }
    &__extract {
      margin: 8px 0 0 0;
      border: 1px dashed #999;
      padding: 2px;
      padding-left: 4px;
      padding-right: 4px;
      color: #3d5170;
    }
  }
}

.w3validator_btns {
  display: flex;
  flex-direction: column;
}
