// Shards Variables Overrides

// Colors
$light: #fbfbfb;
$border-color: #e1e5eb;

// Cards
$card-cap-bg: #fff;

// Typography & Fonts

// Disable Google Fonts imports
$enable-fonts-import: false;

// Use the System UI font instead of Poppins.
$font-family-poppins-first: -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, sans-serif;

$headings-color: #3d5170;
$font-size-sm: 0.6875rem;

// Body
$body-background: #f5f6f8;

$font-size-sm: 0.6875rem;
$font-size-lg: 0.875rem;

// Buttons
$btn-font-size: 0.75rem;
$btn-font-weight: 400;
$btn-border-radius: 0.25rem;
$btn-padding-y: 0.5625rem;
$btn-padding-x: 1rem;

$btn-font-size-sm: 0.6875rem;
$btn-padding-y-sm: 0.4286rem;
$btn-padding-x-sm: 0.875rem;

$btn-border-radius-sm: $btn-border-radius;

// Inputs
$input-btn-padding-y: 0.4375rem;
$input-btn-padding-x: 0.75rem;

$input-group-padding-y: 0.75rem;
$input-group-padding-x: 0.625rem;

// Forms
$input-font-size: 0.8125rem;

$input-padding-y-sm: 0.4286rem;
$input-padding-x-sm: 0.8125rem;

$input-padding-y-lg: 0.8125rem;
$input-padding-x-lg: 0.8125rem;
$input-border-radius-lg: 0.375rem;

$custom-select-line-height: 1.5;

$input-padding-x-sm: 0.625rem;

$input-border-radius: 0.25rem;
$input-border-radius-sm: $input-border-radius;

$custom-select-border-radius: $input-border-radius;

$custom-file-height: calc(2.0625rem + 2px);
$custom-file-border-radius: $input-border-radius;

// Navs
$nav-link-padding-x: 0.625rem;

// Navbar brand
$navbar-brand-font-size: 0.9rem;

// Dropdowns
$dropdown-padding-y: 10px;
