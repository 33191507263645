.score {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  margin: 0 12px 0 4px;

  &--good {
    background: #0cce6b;
    border-radius: 100%;
  }
  &--average {
    background: #ffa400;
  }
  &--bad {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 10px solid #eb0f00;
  }
}

.score-text {
  &--good {
    color: #10b981;
  }
  &--average {
    color: #ffc107;
  }
  &--bad {
    color: #ef4444;
  }
}

.score-number {
  &--good {
    color: #10b981;
  }
  &--average {
    color: #ffc107;
  }
  &--bad {
    color: #ef4444;
  }
}
