.login-container {
  width: 100%;
  padding: 1rem;
  margin-top: 5rem;
}

@media only screen and (min-device-width: 768px) {
  .login-container {
    width: 50%;
    margin: auto;
    margin-top: 4rem;
  }
}

@media only screen and (min-device-width: 1200px) {
  .login-container {
    width: 30%;
  }
}

.btn.btn-link {
  margin-left: 0% !important; 
}
