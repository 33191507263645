.pages-list-expand {
  border: solid #3d5170;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  position: absolute;
  right: 24px;
  top: 45%;
  cursor: pointer;
  transition: all 0.2s linear;

  &--collapsed {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    top: 35%;
    transition: all 0.2s linear;
  }
}

.child-page {
  td {
    #halfcross {
      background: #c0c0c0;
      height: 27px;
      position: relative;
      width: 2px;
    }
    &:first-of-type {
      padding: 0 1rem !important;
    }
  }
  &--last-item {
    td {
      #halfcross {
        background: #c0c0c0;
        height: 25px;
        position: relative;
        width: 2px;
        &:after {
          content: "";
          background: #c0c0c0;
          position: absolute;
          height: 2px;
          left: 0px;
          top: 23px;
          width: 35px;
          @include max(768) {
            width: 20px;
          }
        }
      }
    }
  }
}

.registered-sites {
  @include max(768) {
    width: 100%;
    overflow-x: scroll;
    table {
      tr {
        th:nth-child(3) {
          display: block;
        }
        th:nth-child(4) {
          display: block;
        }
        th:nth-child(5) {
          display: block;
        }
        th:nth-child(6) {
          display: none;
        }
        td:nth-child(3) {
          display: block;
        }
        td:nth-child(4) {
          display: block;
        }
        td:nth-child(5) {
          display: block;
        }
        td:nth-child(6) {
          display: block;
          button {
            margin: 8px 8px 0 0 !important;
          }
        }
      }
    }
    .pages-list-expand {
      display: none;
    }
  }
}

.registered-notifications {
  @include max(768) {
    width: 100%;
    overflow-x: scroll;
    table {
      tr {
        th:nth-child(2) {
          display: none;
        }
        th:nth-child(3) {
          display: none;
        }
        td {
          display: block;
        }
        td:nth-child(1) {
          font-weight: 700;
        }
      }
    }
  }
}

.scrollable-table {
  @include max(768) {
    width: 100%;
    overflow-x: scroll;
  }
}

.search-term-crawled-pages {
  width: 100%;
  padding: 10px;
  border: 0px;
  &:focus {
    border: 1px solid #d32f2f !important;
  }
}

.paginationBtns {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  margin-top: 15px;
  color: #475c76;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
.paginationBtns a {
  padding: 0 10px;
}
.previousBtn {
  color: #475c76;
  margin: 0 0 0 0;
  &:hover {
    text-decoration: none;
    font-weight: bold;
    color: #475c76;
  }
}
.nextBtn {
  color: #475c76;
  margin: 0 0 0 0;
  &:hover {
    text-decoration: none;
    font-weight: bold;
    color: #475c76;
  }
}
.paginationActive {
  color: #475c76;
  font-weight: 600;
}
