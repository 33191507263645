// Borders adjustments
.border {
  border: 1px solid $border-color !important;
}
.border-top {
  border-top: 1px solid $border-color !important;
}
.border-right {
  border-right: 1px solid $border-color !important;
}
.border-bottom {
  border-bottom: 1px solid $border-color !important;
}
.border-left {
  border-left: 1px solid $border-color !important;
}

// Border radius

.rounded {
  border-radius: 5px !important;
}

.rounded-top {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.rounded-right {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-left {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

// Accent color border variation
.border-accent {
  border-color: $accent-color !important;
}
