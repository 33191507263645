// Add any mixins here
@mixin font-style($size, $weight, $style, $color, $line-height) {
  font-size: $size;
  font-weight: $weight;
  font-style: $style;
  color: $color;
  line-height: $line-height;
}

@mixin flex-property($justify-content, $align-items) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}
