.usersByDeviceList {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: space-between;
  padding: 0;
  li {
    margin: 0 0 0 2rem;
    position: relative;
    font-size: 12px;
    &::before {
      content: " ";
      width: 15px;
      height: 10px;
      position: absolute;
      left: -1.3rem;
      bottom: 0;
      top: 4px;
    }
    &:nth-child(1) {
      &::before {
        background: #1985fb;
      }
    }
    &:nth-child(2) {
      &::before {
        background: #7ebafc;
      }
    }
    &:nth-child(3) {
      &::before {
        background: #b2d6fe;
      }
    }
  }
}

.usersByBillings {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 10px;
  margin: 0;
  justify-content: center;
  li {
    padding-bottom: 7.5px;
    margin: 0 0 0 2rem;
    position: relative;
    font-size: 16px;
    &::before {
      content: " ";
      width: 20px;
      height: 16px;
      position: absolute;
      left: -1.6rem;
      bottom: 0;
      top: 4px;
    }
    &:nth-child(1) {
      &::before {
        background: #007bff;
      }
    }
    &:nth-child(2) {
      &::before {
        background: #17c671;
      }
    }
    &:nth-child(3) {
      &::before {
        background: #ffb400;
      }
    }
    &:nth-child(4) {
      &::before {
        background: #c4183c;
      }
    }
  }
}
