.icon-circle-arrow-up {
  margin: 0 0.5rem;
  &::before {
    content: "";
    border: solid #10b981;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin: 0 0.5rem 0 0;
  }
}

.icon-circle-arrow-down {
  margin: 0 0.5rem;
  &::before {
    content: "";
    border: solid #ef4444;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin: 0 0.5rem 0.2rem 0;
  }
}
