.flex-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-right fieldset {
  margin-left: 1rem;
}

.underline:hover {
  text-decoration: underline;
}

@media only screen and (min-device-width: 768px) {
  .nav-toggle {
    order: -1;
  }
}

@include mq(laptop) {
  .nav-toggle {
    visibility: hidden !important;
  }
}
