.overviewSelect {
  height: auto !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  padding: 10px 18px !important;
  color: #3d5170 !important;
}

.link--nohover {
  &:hover {
    text-decoration: none;
  }
}

.link--cursor {
  cursor: pointer;
}

.back-button {
  color: #007bff !important;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .btn-small {
    padding: 6px 4px !important;
  }
}
