.screenshots-analyze {
  &__body {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 1rem 3rem !important;
    @include mq(laptop) {
      flex-direction: row;
      justify-content: center;
    }
  }
  &__differences {
    align-items: center;
    @include mq(laptop) {
      display: flex;
      align-items: baseline;
      flex-direction: column;
    }
  }
  &__arrow {
    display: none !important;
    @include mq(laptop) {
      display: inline-block !important;
    }
  }
}
