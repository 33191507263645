.confirm-backdrop {
  position: absolute;
  top: 4rem;
  width: 100%;
  height: 100%;
  z-index: 10;
  left: 0;
  right: 0;
  background: RGBA(238, 238, 238, 0.8);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
