.form-checkboxes {
  position: relative;
  margin: 0 0 0.5rem 0;
  input {
    position: absolute;
    top: 0.1875rem;
    left: 0;
    width: 1.125rem;
    height: 1.125rem;
    background-color: #fff;
    border: 1px solid #e1e5eb;
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
    box-shadow: none;
    pointer-events: all;
    border-radius: 2px;
  }
  label {
    margin: 0 0 0 2rem;
  }
}

.radio-button-background {
  font-weight: 400;
  @include flex-property(flex-start, center);
  margin: 0 0 0.5rem 0;
  &:last-of-type {
    margin: 0;
  }
  input {
    margin: 0 0.5rem 0 0;
    width: 1.125rem;
    height: 1.125rem;
  }
}
