.uptime {
  display: flex;
  justify-content: flex-end;
  align-content: baseline;
  margin: 16px 0;
  &__up {
    width: 10px;
    height: 60px;
    background: #10b981;
    display: inline-block;
    margin: 8px 0 0 8px;
    border-radius: 2px 2px 0 0;
  }
  &__down {
    width: 10px;
    height: 60px;
    background: #eb0f00;
    display: inline-block;
    margin: 8px 0 0 8px;
    border-radius: 2px 2px 0 0;
  }
  &__legend {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  &__status {
    &--good {
      color: #10b981;
    }
    &--average {
      color: #ffc107;
    }
    &--bad {
      color: #ef4444;
    }
  }
}
