.psi-container {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .rectangle {
    height: 400px;
    width: 36px;
    background: linear-gradient(
      180deg,
      #70f8ba 0%,
      rgba(112, 248, 186, 0) 100%
    );
  }
  .rectangle-error {
    height: 400px;
    width: 36px;
    background: linear-gradient(
      180deg,
      #fb3741 0%,
      rgba(112, 248, 186, 0) 100%
    );
  }
  .psi-score-container {
    margin-top: -20%;
  }
  .circular {
    border: 15px solid #fff;
    border-radius: 50%;
  }
  .score_text {
    font-size: 80px;
    color: #8f8f8f;
  }
  .your-url {
    position: relative;
    align-items: center;
    input {
      width: 100%;
      padding: 0.6em 6.5em 0.6em 0.6em;
      border: none;
      border-radius: 10px;
      box-shadow: 1px 6px 17px -12px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 1px 6px 17px -12px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 1px 6px 17px -12px rgba(0, 0, 0, 0.75);
    }
    button {
      position: absolute;
      right: 0px;
      height: 41px;
      width: 95px;
      border-radius: 10px;
      padding: 13px, 16px, 13px, 16px;
      background-color: #04ca70;
      border: 3px solid #04ca70;
      color: #fff;
    }
    textarea:focus,
    input:focus {
      outline: 0.5px solid rgba(0, 0, 0, 0);
    }
  }
  .bottom-line-green {
    height: 100px;
    width: 36px;
    border-radius: 0px;
    background: linear-gradient(
      180deg,
      rgba(112, 248, 186, 0.62) 0%,
      rgba(112, 248, 186, 0.22) 32.38%,
      rgba(112, 248, 186, 0) 100%
    );
  }
  .bottom-line-blue {
    height: 150px;
    width: 36px;
    border-radius: 0px;
    background: linear-gradient(
      0deg,
      rgba(0, 135, 249, 0) 0%,
      #0089f971 49.74%,
      rgba(0, 135, 249, 0) 100%
    );
  }
}

@media (max-width: 1200px) {
  .psi-container {
    width: 100%;
  }
}
