.loader-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  margin: auto;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1071;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    color: #fff;
  }
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.main-sidebar {
  z-index: 1050 !important;
}
