.metryV-score {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  border: none;
  border-radius: 0.625rem;
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
  min-height: 8.7rem;
  overflow: hidden !important;
  padding: 1rem 1rem 2rem 1rem;
  margin: 1rem 0 0 0;

  h2 {
    font-size: 1.625rem;
    font-weight: 500;
    line-height: 1;
    opacity: 0.9;
  }

  &__container {
    @include flex-property(space-between, flex-start);

    &__overall {
      width: 30%;
      margin-left: 48px;
    }

    &__items {
      width: 70%;
      // margin-top: 24px;

      &__item {
        margin-bottom: 16px;

        &:last-of-type {
          margin: 0;
        }

        &__top {
          @include flex-property(space-between, center);

          &__title {
            margin: 0;
            @include font-style(16px, 500, normal, #3d5170, 24px);
          }

          &__score {
            @include font-style(18px, 500, normal, #3d5170, 27px);
          }
        }

        &__progress {
          margin-top: 8px;
          background: rgba(0, 123, 255, 0.1);
          height: 6px;
          z-index: 1;

          &--good {
            background: rgba(16, 185, 129, 0.1);
          }
          &--average {
            background: rgba(255, 164, 0, 0.1);
          }
          &--bad {
            background: rgba(255, 65, 105, 0.1);
          }
          &--null {
            background: rgba(204, 204, 204, 0.3);
          }

          &--rating {
            height: 6px;
            z-index: 2;
            background: rgba(0, 123, 255, 1);

            &--good {
              background: rgba(16, 185, 129, 1);
            }
            &--average {
              background: rgba(255, 164, 0, 1);
            }
            &--bad {
              background: rgba(255, 65, 105, 1);
            }
            &--null {
              background: rgba(204, 204, 204, 1);
            }
          }
        }
      }
    }
  }

  @include max(768) {
    &__container {
      flex-direction: column;
      &__items {
        width: 100%;
      }
      &__overall {
        width: 100%;
        margin: 4rem auto 0 auto;
        text-align: center;
      }
    }
  }
}

.heading-dashboard {
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 1;
  opacity: 0.9;
}
