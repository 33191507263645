.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle {
    cursor: pointer;
  }
  
  .dropdown-menuu {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    background-color: #fff;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-width: 8rem !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    margin: 0px 0px 0px -65px !important;
  }

  .dropdown-menu.show {
    margin: 0px 0px 0px -65px !important;
  }
  
  .dropdown-itemm {
    padding: 5px;
    cursor: pointer;
  }
  
  .dropdown-itemm:hover {
    background-color: #f0f0f0;
  }