.small-stats-mobile {
  position: relative;
  .hasFID {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 338px;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    z-index: 10;
    display: none;
  }
  &:hover {
    .hasFID {
      display: block;
    }
  }
}
