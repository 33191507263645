@import "utilities/media-queries";
@import "utilities/mixins";
@import "components/navbar";
@import "components/small-stats";
@import "components/loader";
@import "components/circle";
@import "components/scores";
@import "components/hasFID";
@import "components/validations";
@import "components/uptime";
@import "components/w3status";
@import "components/metryv-score";
@import "components/screenshots";
@import "components/confirmed";
@import "components/competitor-labdata-table";
@import "pages/login";
@import "pages/siteDetails";
@import "pages/contactUs";
@import "pages/ranking";
@import "pages/dashboard";
@import "pages/registeredSites";
@import "pages/pricingPlans";
@import "pages/addPropertId";
@import "pages/psiScan";
@import "pages/webVitalsSupport";
@import "components/users-by-device";
@import "components/tictactoe";

@import "template/shards-dashboards";

html {
  scroll-behavior: smooth;
}

.fadeInEffect {
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeHeightEffect {
  animation: fadeHeight 2s;
}

@keyframes fadeHeight {
  from {
    height: auto;
  }
  to {
    height: 100vh;
  }
}

.fadeOutEffect {
  animation: fadeout 1.5s;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
