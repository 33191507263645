.onHoverCards:hover {
  border: 2px solid #0087f9 !important;
  transition: all 0.5s;
  cursor: pointer;
}

.checkout-table {
  :nth-child(2n + 1) {
    td {
      font-weight: bold;
    }
  }
  td {
    padding: 5px;
    text-align: center;
  }
}
