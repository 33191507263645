

.react-datepicker-ignore-onclickoutside {
    color: white;
  }

.react-datepicker__input-container {

  input {
    width: 187px;
    height: 39px;
    background: #FFFFFF;
    border: 2px solid #F1F1F5;
    border-radius: 10px;
    margin-right: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    color: #3D506E;
    margin-bottom: 20px;
    background-image: url("../images/logos/calendar.svg");
    background-repeat: no-repeat;
    background-position: right 20px center;
  }
}

.multiselect-container {
  display: inline-block;
  padding: 0;
  border: 0;

  .searchWrapper {
    width: 377px;
    height: 38px;
    background: #FFFFFF;
    border: 1px solid #F1F1F5;
    border-radius: 10px;
    margin-top: 5px;
    margin-right: 20px;
  }
}

.custom-multiselect {
  border: 1px solid red;
  width: 379px;
  height: 45px;
  border-radius: 10px;
  margin-top: 5px;
  margin-right: 20px;
}







