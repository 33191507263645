.suggestions-link {
  color: inherit;
  &:hover {
    color: inherit;
  }
  &--sub {
    color: #5a6169;
    &:hover {
      color: #5a6169;
    }
  }
}

.pages-link {
  color: inherit;
}

.none {
  display: none !important;
}
