.chat-window {
  width: 100%;
  height: 70vh;
  margin: auto;
}

.chat-window p {
  margin: 0;
}

.chat-window .chat-header {
  height: 45px;
  border-radius: 6px;
  background: #263238;
  position: relative;
  cursor: pointer;
}

.chat-window .chat-header p {
  display: block;
  padding: 0 1em 0 2em;
  color: #fff;
  font-weight: 700;
  line-height: 45px;
}

.chat-window .chat-body {
  height: 80%;
  border: none;
  background: #fff;
  position: relative;
}

.chat-window .chat-body .message-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-window .chat-body .message-container::-webkit-scrollbar {
  display: none;
}

.chat-window .chat-body .message {
  height: auto;
  display: flex;
}

.chat-window .chat-body .message .message-content {
  padding: 10px;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  max-width: 600px;
}

#you {
  justify-content: flex-start;
}

#you .message-content {
  justify-content: flex-start;
}

#you .message-meta {
  justify-content: flex-start;
}

#other {
  justify-content: flex-end;
}

#other .message-content {
  justify-content: flex-end;
}

#other .message-meta {
  justify-content: flex-end;
  margin-right: 10px;
}

.message-meta #author {
  margin-left: 10px;
  font-weight: bold;
}

.chat-window .chat-body .message .message-meta {
  display: flex;
  font-size: 12px;
}

.focus-input:focus {
  outline: 1px solid #afafaf;
  border-radius: 2px;
}

.service-chat {
  border-radius: 25px !important;
  background: transparent !important;
  border: 1px solid rgb(55, 107, 126) !important;
  color: rgb(55, 107, 126);
  transition: all 0.3s;
  &:hover {
    background: rgb(55, 107, 126) !important;
    color: white !important;
    cursor: pointer;
  }
}
