.arrow-position {
  position: relative !important;
}
.arrow-position-item {
  position: absolute !important;
  top: 40%;
  transform: scale(1.25);
}
.arrow-position-item-left {
  position: absolute !important;
  top: 40%;
  right: 40%;
  transform: scale(1.25);
}

.arrow-position-item-right {
  position: absolute !important;
  right: 0px;
  top: 50%;
  transform: scale(1.25);
}

.arrow-position-item-right-left {
  position: absolute !important;
  top: 20%;
  left: 0px;
  transform: scale(1.25);
}
